import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import ActiveStorageUpload from 'uppy-activestorage-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'

document.addEventListener('turbolinks:load', () => {
  // console.log('turbolinks loaded')
  document.querySelectorAll('[data-uppy]').forEach(element => initializeUppy(element))
}, { once: true });

document.addEventListener('turbolinks:render', () => {
  // console.log('turbolinks rendered')
  document.querySelectorAll('[data-uppy]').forEach(element => initializeUppy(element))
});

window.initializeUppy = (element) => {
  // console.log('initializing uppy...')
  let form = element.closest('form')
  let button = form.querySelector('[type=submit]')
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute('content')
  let field_name = element.dataset.uppy

  let documentUploader = Uppy({
    id: 'document',
    // set file name for blob captured from webcam
    onBeforeFileAdded: (file, files) => {
      if(!file.data.name) {
        file.data.name = file.name
      }

      return file
    }
  })
    .use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })
    .use(Dashboard, setDashboardOptions(form))
    .use(Webcam, {
      target: Dashboard,
      countdown: false,
      modes: ['picture'],
      mirror: false,
      facingMode: 'environment',
    })

  document.addEventListener('turbolinks:before-cache', () => {
    documentUploader.close()
  });

  if(button) {
    button.addEventListener('click', function(event){
      event.preventDefault()
      documentUploader.upload()
    })
  }

  documentUploader.on('complete', result => {
    // Add hidden form field with signed id for each successful direct upload
    result.successful.forEach( upload => {
      insertSignedIdHiddenField(element, field_name, upload)
    })

    // disable original file input
    form.querySelector('#documents_field').disabled = true

    // submit form automatically after documents uploaded
    if(form.checkValidity()) {
      if(button) {
        button.disabled = true
        button.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
      }
      form.submit();
    } else {
      form.reportValidity()
    }
  })
}

function insertSignedIdHiddenField(element, field_name, upload) {
  const hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('value', upload.response.signed_id)
  hiddenField.setAttribute('name', field_name)
  element.appendChild(hiddenField)
}

function setDashboardOptions(form) {
  const dashboardOptions = Object.assign({}, {
    target: '#uppy_upload_field',
    replaceTargetContent: false,
    width: "100%",
    height: 300,
    inline: true,
    proudlyDisplayPoweredByUppy: false,
  })
  Object.assign(dashboardOptions, customDashboardOptions(form))
  return dashboardOptions
}

function customDashboardOptions(form) {
  let customOptions = {}
  if(form.classList.contains('form-without-data-fields')) {
    Object.assign(customOptions, {
      locale: {
        strings: {
          complete: 'Submitting document...',
          uploadComplete: 'Completing upload',
        }
      }
    })
  } else if (form.classList.contains('form-with-data-fields')) {
    Object.assign(customOptions, {
      hideUploadButton: true
    })
  }
  return customOptions
}


